//COLORS VARIABLES ==================================
$red: #b20038;

// LOGO ==================================
#sidebar-toggle {
  height: auto;
  width: auto;
  max-width: 50px;
  z-index: 999;
  padding: 5px;

  .picto-icon {
    display: block;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    width: auto;

    img {
      margin: 0 !important;
      max-width: 100%;
    }
  }
}

#error-page {
  .error-content {
    position: relative;
    display: block;
    min-height:100vh;
    background: url('../img/error/error-page-formula-e.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: 0;
    &:after{
      content:'';
      width:100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
      background: rgba(0,0,0,.7);
      z-index: -1;
    }
  }

  .container > .row {
    min-height:100vh;
  }

  .error-content section{
    margin: auto;
  }

  .inner {
    position: absolute;
    left: 11%;
    bottom: 15vh;
    text-align: left;
    width: 78%;
  }

  .inner a {
    transition: .4s all ease;
    position: relative;
    margin-top: 35px !important;
    font-size: 1em;
    padding: 0 20px 0 0;
    display: table;
    color: white;
    font-weight: 100;
    border: 0;
    background: transparent !important;
    letter-spacing: 1px;

    .arrow {
      transition: .4s all ease;
      position: absolute;
      right: 8px;
      top: 0;
      width: 15px;
      opacity: 0;
    }
    &:hover {
      color: #e30047;
      .arrow {
        opacity: 1;
        right: -7px;
      }
    }
  }

  .inner p{
    text-align: left;
    color: white;
    letter-spacing: .5px;
  }

  .inner h1 {
    font-size: 9.5em;
    line-height: 1;
    padding: 0;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 400;
    color: white;
  }

  .inner h2 {
    padding: 0;
    margin-bottom: 5px;
    text-align: left;
    font-weight: bolder;
    color: white;
    letter-spacing: .5px;
  }

  .brand-logo {
    display: block !important;

    height: 69px;
    width: 72px;
    left: 0;
    top: 0;
    padding: 4px 8px;
  }

  [class^="move-cloud"]{
    position: absolute;
    display: block;
    animation: moveCloud 45s linear infinite;
  }

  .move-cloud {
    top: 50%;
    transform: translateY(-50%);
  }

  .move-cloud2 {
    top: 0;
    animation-duration: 35s;
  }

  .move-cloud3 {
    bottom: 0;
    animation-duration: 30s;
  }

  @keyframes moveCloud {
    from {left: -73%;}
    to {left: 100%;}
  }

  @media screen and (max-width: 768px) {

    .container > .row {
      min-height:auto;
    }

    .error-content {
      display: flex;
    }

    .inner {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    .inner h1{
      font-size: 10.5em;
    }
    .inner, .inner h1, .inner h2, .inner p {
      text-align: center;
    }
    .inner a, .inner button{
      text-align: center;
      display: inline-block;
    }
    section {
      padding: 1em 3em 2.2em 3em !important;
    }
    .brand-logo {
      display: none !important;
    }
  }
}